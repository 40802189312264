import { createContext, useReducer, useContext, useEffect, Suspense, lazy } from 'react'
import { Route, Routes, BrowserRouter, useLocation } from 'react-router-dom';
import { createRoot } from 'react-dom/client'
import { fetchGeneralData, checkRedirect } from './services/api.js';
const Home = lazy(() => import('./pages/Home.jsx'))
const ArticlePage = lazy(() => import('./pages/Article.jsx'))
const Title = lazy(() => import('./components/Title'))
const Loading = lazy(() => import('./components/Loading.jsx'))
const Layout = lazy(() => import('./components/Layout.jsx'))
const ScrollToHash = lazy(() => import('./components/ScrollToHash.jsx'))
const LazyCSSLoader = lazy(() => import('./components/LazyCSSLoader.jsx'))

const Ctx = createContext(null)
export const useCtx = () => {
  return useContext(Ctx)
}

const defaultTitle = "Hvidbjerg Bank"

const App = () => {
  const cssFiles = [
    '/css/view.css',
    '/css/smartmenu-core.css',
    '/css/smartmenu-clean.css',
    '/css/main.css',
    '/css/style.min.css',
    '/css/fontawesome.css',
    '/css/index.css',
    '/css/layout.css',
  ]
  const { pathname } = useLocation()
  const initialState = {
    appName: 'hvidbjerg-bank',
    appUrl: import.meta.env.VITE_APP_URL,
    isFinished: false,
    isNewsItem: false,
    isFeaturedBanner: false,
    logo: '',
    latestNews: [],
    facebookImg: '',
    leftBannerImg: '',
    rightBannerImg: '',
    leftBannerUrl: '',
    rightBannerUrl: '',
    newsBannerImg: '',
    footerDocs: '',
    floatingNav: [],
    footer: [],
    navbar: [],
    homeCarousel: [],
    featuredBannerImg: '',
    homeConfig: {
      isShowDualBannerCTA: false,
      isShowFacebookCTA: false,
      isShowLatestNews: false,
    }
  }

  const reducer = (state, action) => {
    const val = action?.payload;
    switch (action.type) {
      case 'SET_LOGO_IMG':
        return { ...state, logo: val || '' };
      case 'SET_LATEST_NEWS':
        return { ...state, latestNews: val || '' };
      case 'SET_FB_IMG':
        return { ...state, facebookImg: val || '' };
      case 'SET_LEFTBANNER_IMG':
        return { ...state, leftBannerImg: val || '' };
      case 'SET_RIGHTBANNER_IMG':
        return { ...state, rightBannerImg: val || '' };
      case 'SET_FOOTER_DOCS':
        return { ...state, footerDocs: val || '' };
      case 'SET_GENERAL_DATA':
        return {
          ...state,
          leftBannerUrl: val?.leftBannerCTALink[0]?.route?.path || '',
          rightBannerUrl: val?.rightBannerCTALink[0]?.route?.path || '',
          footer: val?.officeLocations?.items || [],
          navbar: val?.navigation?.items || [],
          floatingNav: val?.floatingMenu?.items || [],
          homeCarousel: val?.homeCarousel?.items || [],
          featuredBannerImg: val?.articlePageFeaturedBanner[0]?.url || '',
          homeConfig: {
            ...state.homeConfig,
            isShowDualBannerCTA: val?.isShowDualBannerCTA || false,
            isShowFacebookCTA: val?.isShowFacebookCTA || false,
            isShowLatestNews: val?.isShowLatestNews || false,
          }
        };
      case 'SET_ISFINISHED':
        return { ...state, isFinished: true }
      case 'SET_ISFEATUREDBANNER':
        return { ...state, isFeaturedBanner: val || false }
      case 'SET_NEWSBANNER_IMG':
        return { ...state, newsBannerImg: val || '' }
      case 'SET_ISNEWSITEM_PAGE':
        return { ...state, isNewsItem: val || '' }
      default:
        throw new Error();
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const checkRedirectUrl = async () => {
      try {
        const res = await checkRedirect(pathname);
        if (res.redirect) return window.location.href = res.url.fullUrl
      } catch (error) {
        setError(error?.code);
      }
    }

    checkRedirectUrl()
    fetchGeneralData(dispatch)
  }, [])

  useEffect(() => {
    dispatch({ type: "SET_ISNEWSITEM_PAGE", payload: false })
    dispatch({ type: "SET_ISFEATUREDBANNER", payload: false })
    window.scrollTo(0, 0)
  }, [pathname])

  if (!state.isFinished) return <Loading />

  return (
    <Ctx.Provider value={{ ...state, dispatch }}>
      <Suspense fallback={<Loading />}>
        <Layout>
          {state.isFinished && <ScrollToHash />}
          {/* <LazyCSSLoader cssFiles={cssFiles} /> */}
          <Routes>
            <Route
              path='/'
              element={
                <>
                  <Title title={defaultTitle} />
                  <Home />
                </>
              }
            />
            <Route
              path='*'
              element={<ArticlePage />}
            />
          </Routes>
        </Layout>
      </Suspense>
    </Ctx.Provider>
  )
}

createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)