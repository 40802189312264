import axios from 'axios';
import { formatDate } from '../helper/fn';
const VITE_APP_URL = import.meta.env.VITE_APP_URL;
const VITE_API_URL = import.meta.env.VITE_API_URL;
const VITE_CONTENT_API_URL = import.meta.env.VITE_CONTENT_API_URL;
const VITE_FORM_API_URL = import.meta.env.VITE_FORM_API_URL;
const VITE_MEDIA_API_URL = import.meta.env.VITE_MEDIA_API_URL;
const VITE_API_KEY = import.meta.env.VITE_PUBLIC_ENV__API_KEY;
const VITE_NEWS_GUID = import.meta.env.VITE_NEWS_GUID;
const VITE_MEDIA_LOGO_GUID = import.meta.env.VITE_MEDIA_LOGO_GUID;
const VITE_MEDIA_LEFTBANNER_GUID = import.meta.env.VITE_MEDIA_LEFTBANNER_GUID;
const VITE_MEDIA_RIGHTBANNER_GUID = import.meta.env.VITE_MEDIA_RIGHTBANNER_GUID;
const VITE_MEDIA_FACEBOOK_GUID = import.meta.env.VITE_MEDIA_FACEBOOK_GUID;
const VITE_PDF_FOOTER_GUID = import.meta.env.VITE_PDF_FOOTER_GUID;

const fetch = async (url = VITE_API_URL, retries = 3, delay = 1000) => {
    const client = axios.create({ baseURL: url });

    client.interceptors.request.use(
        (config) => {
            config.headers['api-key'] = VITE_API_KEY;
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    const attemptRequest = async (attempt = 1) => {
        try {
            const response = await client();
            return response.data;
        } catch (error) {
            if (error.response?.status === 500 && attempt < retries) {
                await new Promise((resolve) => setTimeout(resolve, delay));
                return attemptRequest(attempt + 1);
            }
            throw error;
        }
    };

    return attemptRequest();
};

export const fetchGeneralData = async (dispatch) => {
    Promise.allSettled([
        fetch(VITE_MEDIA_API_URL + `/${VITE_MEDIA_LOGO_GUID}`), // app logo
        // fetch(VITE_CONTENT_API_URL + `?fetch=children:${VITE_NEWS_GUID}&sort=publishedOn:desc&take=3`), // latest news
        fetch(VITE_CONTENT_API_URL + `?filter=contentType:newsItem&sort=publishedOn:desc&take=3`), 
        fetch(VITE_MEDIA_API_URL + `/${VITE_MEDIA_FACEBOOK_GUID}`), // fb img
        fetch(VITE_MEDIA_API_URL + `/${VITE_MEDIA_LEFTBANNER_GUID}`), // left banner img
        fetch(VITE_MEDIA_API_URL + `/${VITE_MEDIA_RIGHTBANNER_GUID}`), // right banner img
        fetch(VITE_MEDIA_API_URL + `/${VITE_PDF_FOOTER_GUID}`), // footer docs pdf
        fetch(), // home
    ])
        .then((responses) => {
            const successfulResponses = responses
                .filter((res) => res.status === 'fulfilled')
                .map((res) => res.value);
            const failedResponses = responses
                .filter((res) => res.status === 'rejected')
                .map((res) => res.reason);
            if (failedResponses.length > 0) {
                console.error('🔥 Some requests failed:', failedResponses.length);
            }
            return Promise.all(successfulResponses.map((response) => response));
        })
        .then((res) => {
            const errorResponse = res.find((response) => response.status && response.status >= 400);
            if (errorResponse) {
                throw new Error(`Request failed with status ${errorResponse.status}`);
            }
            dispatch({ type: "SET_LOGO_IMG", payload: VITE_APP_URL + res[0]?.url });
            dispatch({ type: "SET_LATEST_NEWS", payload: res[1]?.items });
            dispatch({ type: "SET_FB_IMG", payload: VITE_APP_URL + res[2]?.url });
            dispatch({ type: "SET_LEFTBANNER_IMG", payload: VITE_APP_URL + res[3]?.url });
            dispatch({ type: "SET_RIGHTBANNER_IMG", payload: VITE_APP_URL + res[4]?.url });
            dispatch({ type: "SET_FOOTER_DOCS", payload: VITE_APP_URL + res[5]?.url });
            dispatch({ type: "SET_GENERAL_DATA", payload: res[6]?.properties });

            return dispatch({ type: "SET_ISFINISHED" });
        });
}

export const checkRedirect = async (url) => {
    const res = await fetch(VITE_APP_URL + `/umbraco/api/redirect/checkUrl?url=${url}`);
    return res
}

export const fetchDynamicUrl = async (url) => {
    const res = await fetch(VITE_API_URL + url)

    // fetch form
    const getFormId = async (markup) => {
        const macroRegex = /<\?UMBRACO_MACRO macroAlias="renderUmbracoForm" FormGuid="([^"]+)"[^>]*>/g;
        let formGuid;
        if (macroRegex.test(markup)) formGuid = markup.match(macroRegex)[0].match(/FormGuid="([^"]+)"/)[1];
        return formGuid;
    };

    const foundRTE = res?.properties?.articleContent?.items.find(e => e?.content?.contentType === 'richtextEditorItem')
    const rteMarkup = foundRTE?.content?.properties?.richtextContent?.markup
    const formId = await getFormId(rteMarkup)

    if (formId !== undefined) {
        const formData = await fetch(VITE_FORM_API_URL + `/definitions/${formId}`)
        res.form = formData
    }

    return res
}

export const fetchNews = async (length = 3) => {
    // return await fetch(VITE_CONTENT_API_URL + `?fetch=children:${VITE_NEWS_GUID}&sort=publishedOn:desc&take=${length}`)
    return await fetch(VITE_CONTENT_API_URL + `?filter=contentType:newsItem&sort=publishedOn:desc&take=${length}`)
    
    // return await fetch(VITE_CONTENT_API_URL + `?filter=contentType:newsItem&fields=properties[isListedOnNewsPage:true,content,excerpts,imageBanner,imageThumbnail,pageTitle,publishedOn,redirectUrl]&sort=publishedOn:desc&take=${100}`)
    //     .then(response => {
    //         const filteredItems = response.items.filter(item => item.properties.isListedOnNewsPage === true);
    //         console.log('resrreres', response)
    //         return {total: filteredItems.length, items: filteredItems}
    //     })
}

export const fetchMoreNews = async (data) => {
    const res = await fetch(VITE_APP_URL + `/umbraco/api/newspage/loadmorenews?skip=${data?.currentPage * data?.limit}&take=${data?.limit}`)
    const transformedData = res.map(item => ({
        contentType: "newsItem",
        name: item.title,
        createDate: formatDate(item.createdDate),
        route: {
            path: item.url,
        },
        properties: {
            pageTitle: item.title,
            imageThumbnail: [
                {
                    url: item.imgUrl,
                }
            ],
            excerpts: item.excerpts,
            publishedOn: formatDate(item.publishedOn),
        },
    }));
    return transformedData
}

export const fetchSearchPage = async (query = '', page = 1) => {
    const res = await fetch(VITE_APP_URL + `/umbraco/api/search/search?query=${query}&skip=0&pageSize=10&page=${page}`)
    return res
}

export const fetchErr = async () => {
    const res = await fetch("https://httpstat.us/500")
    return res
}