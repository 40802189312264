const appUrl = import.meta.env.VITE_APP_URL
const apiKey = import.meta.env.VITE_PUBLIC_ENV__API_KEY

export const isNotEmptyArray = (value) => Array.isArray(value) && value.length > 0;
export const fetchApi = async (url, options) => {
    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error(`Request to ${url} failed with status ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error(`Error fetching ${url}:`, error);
        throw error; // Re-throw for higher-level handling
    }
};

export function extractMatchingParagraphs(htmlContent, query, maxParagraphs = 1) {
    if (!htmlContent || htmlContent.trim() === "") {
        return "Content is empty.";
    }

    if (!query || query.trim() === "") {
        return "Query is empty.";
    }
    const container = document.createElement("div");
    container.innerHTML = htmlContent;
    const elements = container.querySelectorAll("p, h3");
    if (elements.length === 0) {
        return "No paragraphs found.";
    }

    const matchingParagraphs = [];
    const lowerCaseQuery = query.toLowerCase();

    for (const element of elements) {
        if (element.textContent.toLowerCase().includes(lowerCaseQuery)) {
            matchingParagraphs.push(element.textContent.trim());

            if (matchingParagraphs.length >= maxParagraphs) {
                break;
            }
        }
    }

    if (matchingParagraphs.length === 0) {
        return "Ingen matchende afsnit fundet.";
    }
    return matchingParagraphs.join("\n\n");
}


export function highlightUrl(url, query) {
    if (!query || query.trim() === "") {
        return url;
    }

    const escapedQuery = query.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    const regex = new RegExp(escapedQuery, "gi");
    const highlightedUrl = url.replace(regex, (match) => {
        return `<span style="background-color:#EFE795;">${match}</span>`;
    });

    return highlightedUrl;
}

export const formatDate = (inputDate) => {
    const months = {
        jan: "01",
        feb: "02",
        mar: "03",
        apr: "04",
        maj: "05",
        jun: "06",
        jul: "07",
        aug: "08",
        sep: "09",
        okt: "10",
        nov: "11",
        dec: "12",
    };

    const datePattern = /^(\d{2})\. (\w{3})\.? (\d{4})$/;
    const match = inputDate.match(datePattern);

    if (!match) {
        throw new Error("Invalid date format");
    }

    const day = match[1];
    const month = months[match[2].toLowerCase()];
    const year = match[3];

    if (!month) {
        throw new Error("Invalid month");
    }
    return `${year}-${month}-${day}T00:00:00Z`;
}

export const loadMoreNews = async (data) => {
    const res = await fetch(appUrl + `umbraco/api/newspage/loadmorenews?skip=${data?.currentPage * data?.limit}&take=${data?.limit}`, { headers: { "api-key": apiKey } })
    const resData = await res.json()

    const transformedData = resData.map(item => ({
        contentType: "newsItem",
        name: item.title,
        createDate: formatDate(item.createdDate),
        route: {
            path: item.url,
        },
        properties: {
            imageThumbnail: [
                {
                    url: item.imgUrl,
                }
            ],
            excerpts: item.excerpts,
            publishedOn: formatDate(item.publishedOn),
        },
    }));
    return transformedData
}

export const searchContent = async (query, page) => {
    const res = await fetch(appUrl + `umbraco/api/search/search?query=${query}&skip=0&pageSize=10&page=${page}`, { headers: { "api-key": apiKey } })
    const resData = await res.json()
    console.log('searchContent resData ====', resData);
    return resData;
}

async function fetchWithRetry(url, options, retries = 3) {
    for (let attempt = 1; attempt <= retries; attempt++) {
        try {
            console.log("fetching url: " + url + ", with attempt", attempt)
            const res = await fetch(url, options);
            if (res.ok) { // Check if status is 200-299
                return res; // Return raw response
            }
            console.log("attempting failed with status:" + res.status + ", and total attempt:" + attempt);
            if (attempt < retries) {
                await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1s before retrying
            } else {
                throw new Error(`Fetch failed after ${retries} attempts. Status: ${res.status}`);
            }
        } catch (error) {
            console.log(`Attempt ${attempt} failed: ${error.message}`);
            if (attempt === retries) throw error;
        }
    }
}
export default fetchWithRetry;

export const updateMarkup = (thumbnail, markup) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(markup, "text/html");
    const paragraphs = Array.from(doc.querySelectorAll("p"));

    const medianIndex = Math.ceil(paragraphs.length / 2);

    const imgElement = `<img src="${thumbnail}" alt="Post Thumbnail" style="width: 100%; max-width: 600px; margin: 20px auto; display: block;" />`;

    const updatedMarkup = paragraphs.reduce((acc, paragraph, index) => {
        acc += paragraph.outerHTML;
        if (index === medianIndex - 1) {
            acc += imgElement;
        }
        return acc;
    }, "");

    return updatedMarkup;
};

export const checkImgHorizontal = (imageUrl) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imageUrl;

        img.onload = () => {
            resolve((img.width - 150) > img.height);
        };

        img.onerror = () => {
            reject(new Error("Failed to load image"));
        };
    });
};